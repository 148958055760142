<template>
  <v-card width="400">
    <v-card-title class="lead-card-title">
      <v-col v-if="userRole !== 'marketing'" cols="12" class="ma-0 pa-0">
        <Tags-And-Create
          :itsImmediate="itsImmediate(lead.created_at, lead.reviewed)"
          :getCreatedAtDate="dateFormat(lead.created_at)"
          :brex="false"
          :lead="lead"
          view="kanban"
        ></Tags-And-Create>
        <!-- <v-row justify="space-between" align="center" class="ma-0 pa-0">
          <div></div>
          <div class="caption grey--text">
            {{ "(" + dateFormat(lead.created_at) + ")" }}
          </div>
        </v-row> -->
      </v-col>
      <v-col v-if="userRole !== 'marketing'" cols="12" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <!-- <div v-if="!itsImmediate(lead.created_at, lead.reviewed)">
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="amber lighten-3 mr-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small color="amber darken-2">
                    mdi-lightning-bolt
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Este prospecto fue atendido en menos de 5 minutos desde que
                ingresó al sistema
              </span>
            </v-tooltip>
          </div> -->
          <div>
            <div v-if="lead.contact_lead_name" class="font-weight-light">
              {{ lead.contact_lead_name }}
            </div>
            <div v-else class="font-weight-light">
              Sin Nombre
            </div>
          </div>
        </v-row>
      </v-col>
    </v-card-title>

    <v-card-text
      class="py-0"
      v-if="userRole !== 'marketing' && type === 'email'"
    >
      <v-col cols="12" class="mb-2">
        <v-row>
          Correo:
          <v-chip
            class="mx-1"
            color="red lighten-5"
            text-color="red darken-4"
            label
            small
          >
            {{ lead.contact_lead.email }}
          </v-chip>
          <!-- <v-chip
            v-if="budget"
            class="mx-1 font-weight-medium  green--text"
            label
            small
            color="green lighten-5"
          >
            {{ moneyFormatter.format(budget) }} {{ currency ? currency : "" }}
          </v-chip> -->
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-text
      class="py-0"
      v-if="userRole !== 'marketing' && type === 'phone'"
    >
      <v-col cols="12" class="mb-2">
        <v-row>
          Teléfono:
          <v-chip
            class="mx-1"
            color="red lighten-5"
            text-color="red darken-4"
            label
            small
          >
            {{ lead.contact_lead.phone }}
          </v-chip>
          <!-- <v-chip
            v-if="budget"
            class="mx-1 font-weight-medium  green--text"
            label
            small
            color="green lighten-5"
          >
            {{ moneyFormatter.format(budget) }} {{ currency ? currency : "" }}
          </v-chip> -->
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-text class="py-0" v-if="userRole !== 'marketing'">
      <v-col v-if="lead.zones" cols="12" class="mb-2">
        <v-row>
          <v-chip class="mx-1" label small>
            {{ lead.zones }}
          </v-chip>
          <!-- <v-chip
            v-if="budget"
            class="mx-1 font-weight-medium  green--text"
            label
            small
            color="green lighten-5"
          >
            {{ moneyFormatter.format(budget) }} {{ currency ? currency : "" }}
          </v-chip> -->
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-text
      class="py-0"
      v-if="lead.phase === 'discarded' && lead.discard_observations"
    >
      <v-row align="center" no-gutters>
        <v-col
          class="body-2 font-weight-light text--disabled lead-observations"
        >
          <span class="font-weight-bolder black--text">Notas descarte:</span>
          {{ lead.discard_observations }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-subtitle class="lead-card-subtitle">
      <v-col v-if="userRole !== 'marketing'" cols="12" class="ma-0 pa-0">
        <v-row
          justify="space-between"
          align="center"
          class="ma-0 pa-0 mx-2 my-2"
        >
          <div class="font-size-table-row">
            <v-row justify="start" align="center">
              <div class="pa-2 blue lighten-5 rounded-circle d-inline-block">
                <v-img
                  contain
                  :src="
                    lead.contact &&
                    lead.contact.source &&
                    lead.contact.source.media
                      ? lead.contact.source.media.src
                      : ''
                  "
                  width="20px"
                  height="20px"
                >
                </v-img>
              </div>
              <div
                v-if="
                  lead.contact &&
                    lead.contact.source &&
                    lead.contact.source.name
                "
                class="mx-2"
              >
                {{ lead.contact.source.name }}
              </div>
              <div v-else class="mx-2">
                Indefinido
              </div>
            </v-row>
          </div>
          <!-- <div>
            <div>
              <v-img
                class="profile-termometer-image"
                contain
                :src="getProfileImage(lead.profile)"
                max-width="35"
                max-height="35"
              ></v-img>
            </div>
          </div> -->
        </v-row>
      </v-col>
    </v-card-subtitle>
    <v-card-text class="py-0"> </v-card-text>
    <v-divider class="mx-4 mt-3"></v-divider>
    <v-card-actions>
      <!-- <v-row>
        <v-col :cols="brokerName ? '3' : '4'">
          <v-row>
            <v-avatar color="primary" size="32">
              <v-img :src="srcBrokerImage"></v-img>
            </v-avatar>
            <v-col>

            </v-col>
          </v-row>

        </v-col>
        <v-col :cols="brokerName ? '3' : '4'"></v-col>
        <v-col :cols="brokerName ? '3' : '4'"></v-col>
        <v-col :cols="brokerName ? '3' : '4'"></v-col>


      </v-row> -->
      <v-list-item v-if="brokerName" class="px-0 ml-2">
        <v-list-item-avatar size="32">
          <v-img :src="srcBrokerImage"></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="ma-0 pa-0">
          <v-list-item-title class="broker-name">
            <v-row class="ma-0 pa-0" justify="start" align="center">
              {{ brokerName.split(" ")[0] }}
            </v-row>
          </v-list-item-title>
          <v-list-item-subtitle>
            <div class="lead-num-comments">
              <v-row class="ma-0 pa-0" justify="start" align="center">
                <svg
                  class="ma-0 pa-0"
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2C17.1046 2 18 2.89543 18 4L4 4L4 15.1765C2.89543 15.1765 2 14.281 2 13.1765V4C2 2.89543 2.89543 2 4 2H16Z"
                    fill="#A0A2A7"
                  />
                  <path
                    d="M14 22L11.3333 19.1765H8C6.89543 19.1765 6 18.281 6 17.1765V8C6 6.89543 6.89543 6 8 6H20C21.1046 6 22 6.89543 22 8V17.1765C22 18.281 21.1046 19.1765 20 19.1765H16.6667L14 22ZM15.8046 17.1765L20 17.1765V8L8 8V17.1765H12.1954L14 19.0872L15.8046 17.1765Z"
                    fill="#A0A2A7"
                  />
                </svg>
                <div
                  v-if="lead && lead.totalComment"
                  class="mx-1 mt-2 icon-comment"
                >
                  {{ lead.totalComment }}
                </div>
                <div v-else class="mx-1 mt-2 icon-comment">
                  0
                </div>
              </v-row>
            </div></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else class="px-0">
        <v-list-item-content class="ma-0 pa-0">
          <v-list-item-subtitle>
            <div class="lead-num-comments">
              <v-row class="ma-0 pa-0" justify="start" align="center">
                <svg
                  class="ma-0 pa-0"
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2C17.1046 2 18 2.89543 18 4L4 4L4 15.1765C2.89543 15.1765 2 14.281 2 13.1765V4C2 2.89543 2.89543 2 4 2H16Z"
                    fill="#A0A2A7"
                  />
                  <path
                    d="M14 22L11.3333 19.1765H8C6.89543 19.1765 6 18.281 6 17.1765V8C6 6.89543 6.89543 6 8 6H20C21.1046 6 22 6.89543 22 8V17.1765C22 18.281 21.1046 19.1765 20 19.1765H16.6667L14 22ZM15.8046 17.1765L20 17.1765V8L8 8V17.1765H12.1954L14 19.0872L15.8046 17.1765Z"
                    fill="#A0A2A7"
                  />
                </svg>
                <div
                  v-if="lead && lead.totalComment"
                  class="mx-1 mt-2 icon-comment"
                >
                  {{ lead.totalComment }}
                </div>
                <div v-else class="mx-1 mt-2 icon-comment">
                  0
                </div>
              </v-row>
            </div></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <div class="profile-percentage">
          <v-icon color="accent" class="ml-1 mb-1">
            mdi-auto-fix
          </v-icon>
          <span v-if="lead && lead.profile_percentage">
            {{ lead.profile_percentage | percentageFormat }} %
          </span>
          <span v-else>
            0 %
          </span>
        </div>
      </v-list-item>
      <v-list-item class="px-0 mr-2">
        <div v-if="expiredToday(lead)" class="lead-postponed">
          <v-icon v-if="showPostponedIcon()" color="green">
            mdi-clock
          </v-icon>
          <small
            class="lead-postponed-time title font-weight-light caption green--text"
          >
            {{
              getPostponedDate(
                lead && lead.postponed && lead.postponed.date_at
                  ? lead.postponed.date_at
                  : new Date()
              )
            }}
          </small>
        </div>
        <div v-else class="lead-postponed">
          <v-icon
            v-if="showPostponedIcon()"
            :color="
              isDelayed(
                lead && lead.postponed && lead.postponed.date_at
                  ? lead.postponed.date_at
                  : new Date()
              )
                ? 'red'
                : 'blue'
            "
          >
            mdi-clock
          </v-icon>
          <small v-if="lead.phase === 'finished'" :class="'grey--text'">
            {{
              getPostponedDate(
                lead && lead.postponed && lead.postponed.date_at
                  ? lead.postponed.date_at
                  : new Date()
              )
            }}
          </small>
          <small
            v-else
            :class="
              isDelayed(
                lead && lead.postponed && lead.postponed.date_at
                  ? lead.postponed.date_at
                  : new Date()
              )
                ? 'red--text'
                : 'blue--text'
            "
          >
            {{
              getPostponedDate(
                lead && lead.postponed && lead.postponed.date_at
                  ? lead.postponed.date_at
                  : new Date()
              )
            }}
          </small>
        </div>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  components: {
    TagsAndCreate: () => import("@/components/lead/components/IconTagsLead.vue")
  },
  props: {
    lead: {
      type: Object,
      default: function() {
        return {};
      }
    },
    brokerName: {
      type: String,
      default: ""
    },
    srcBrokerImage: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      userRole: localStorage.getItem("user_role") || ""
    };
  },
  filters: {
    percentageFormat(percentage) {
      if (!percentage || isNaN(percentage)) return 0;
      let percentageParsed = parseInt(percentage);
      return percentageParsed;
    }
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    getProfileImage(profile) {
      let image = "";
      switch (profile) {
        case "A":
          image = `${this.publicPath}50rojo.png`;
          break;
        case "B":
          image = `${this.publicPath}50naranja.png`;
          break;
        case "C":
          image = `${this.publicPath}50amarillo.png`;
          break;
        case "D":
          image = `${this.publicPath}50azul.png`;
          break;
        default:
          image = `${this.publicPath}vacio.png`;
          break;
      }
      return image;
    },
    itsImmediate(created, reviewed) {
      if (reviewed) {
        if (reviewed.is_reviewed != null && reviewed.is_reviewed) {
          var diff = Math.abs(new Date(reviewed.date_at) - new Date(created));
          const diffMinutes = Math.floor(diff / 1000 / 60);
          return diffMinutes <= 5;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    dateFormat(data) {
      return moment(data).format("LLL");
    },
    showPostponedIcon() {
      return true;
    },
    expiredToday(lead) {
      if (
        !lead ||
        !lead.postponed ||
        lead.postponed.date_at === undefined ||
        lead.postponed.date_at === null
      ) {
        return false;
      } else {
        let now = new Date();
        let date = new Date(lead.postponed.date_at);
        if (
          now.getFullYear() === date.getFullYear() &&
          now.getMonth() === date.getMonth() &&
          now.getDate() === date.getDate()
        ) {
          if (date.getTime() > now.getTime()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    isDelayed(date) {
      return new Date(date).valueOf() < new Date().valueOf();
    },
    getPostponedDate(date) {
      let now = new Date();
      let leadDate = new Date(date);
      if (
        now.getDate() === leadDate.getDate() &&
        now.getMonth() === leadDate.getMonth() &&
        now.getFullYear() === leadDate.getFullYear()
      ) {
        // return "Hoy";
        return this.getCreatedAtDate(date);
      } else {
        return this.getCreatedAtDate(date);
      }
    },
    getCreatedAtDate(date) {
      if (date === undefined || date === null || date === "") {
        return new Date().getMonth() === 1
          ? "1 mes"
          : Math.round(new Date().getMonth()) + "meses";
      } else {
        let now = new Date();
        let leadDate = new Date(date);
        let oneDay = 24 * 60 * 60 * 1000;
        if (
          leadDate.getDate() === now.getDate() &&
          leadDate.getMonth() === now.getMonth() &&
          leadDate.getFullYear() === now.getFullYear()
        ) {
          // return "Hoy";
          let diffMinutes = Math.round(Math.abs((leadDate - now) / 60 / 1000));
          if (diffMinutes <= 1) {
            return diffMinutes === 0 ? "Ahora" : "1 min";
          } else {
            if (diffMinutes <= 59) {
              return Math.round(diffMinutes) + " min";
            } else {
              if (Math.round(Math.abs(diffMinutes / 60)) <= 1) {
                return "1 hora";
              } else {
                return Math.round(Math.abs(diffMinutes / 60)) + " horas";
              }
            }
          }
        } else {
          if (Math.round(Math.abs((leadDate - now) / oneDay)) < 30) {
            if (Math.round(Math.abs((leadDate - now) / oneDay)) === 1) {
              return "1 día";
            } else {
              return Math.round(Math.abs((leadDate - now) / oneDay)) + " días";
            }
          } else {
            if (this.monthDiff() > 11) {
              if (this.monthDiff() === 12) {
                return "1 año";
              } else {
                return Math.round(this.monthDiff() / 12) + " años";
              }
            } else {
              if (this.monthDiff() === 1) {
                return "1 mes";
              } else {
                return Math.round(this.monthDiff()) + " meses";
              }
            }
          }
        }
      }
    },
    monthDiff() {
      let start = moment(this.lead.postponed.date_at);
      let end = moment();
      if (this.isDelayed(this.lead.postponed.date_at)) {
        let diffDays = moment.duration(start.diff(end)).asDays();
        if (isNaN(diffDays)) {
          return Math.round(new Date().getMonth() + 1);
        } else {
          return Math.round(Math.abs(Math.round(diffDays / 30)));
        }
      } else {
        let diffDays = moment.duration(end.diff(start)).asDays();
        if (isNaN(diffDays)) {
          return Math.round(new Date().getMonth() + 1);
        } else {
          return Math.round(Math.abs(Math.round(diffDays / 30)));
        }
      }
    },
    priceFormat(price, currency) {
      if (parseFloat(price) <= 0 || isNaN(price)) {
        return "-";
      }
      let cu = currency;
      // si se especifico una moneda para todos los precios
      // hacer la conversion
      if (this.currencyPrices && this.currencyPrices !== "") {
        cu = this.currencyPrices;
        if (this.currencyPrices === "MXN" && currency === "USD")
          price = price * 20;
        else if (this.currencyPrices === "USD" && currency === "MXN")
          price = price / 20;
      }
      return (
        parseFloat(price)
          // .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .concat(" " + cu)
      );
    }
  }
};
</script>
<style scoped>
.uri-text {
  display: inline-block;
  margin-left: 5px;
}

.link {
  color: blue !important;
  background-color: none;
}
.broker-avatar {
  padding: 0 !important;
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}
.broker-name {
  display: block;
  font-size: 8px;
  width: 80px;
  margin-top: 2px;
}
.lead-budget {
  color: #008000;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-percentage {
  color: #ab47bc;
  width: 100%;
  text-align: center;
}
.lead-observations {
  text-align: justify;
}
.lead-num-comments {
  color: #9e9e9e;
}
.lead-postponed {
  width: 100%;
  text-align: right;
}
.profile-termometer-image {
  position: absolute;
  left: 0;
  top: 24px;
}
.body-1.has-profile,
.lead-card-subtitle.has-profile {
  margin-left: 26px;
}
.lead-name {
  max-width: 258px;
}
.lead-name.has-profile {
  max-width: 222px;
}
.lead-mkt-title {
  font-size: 14px;
  font-weight: 300;
}
</style>
